import styled, { css } from 'styled-components';

export const AbasContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  cursor: pointer;
  text-align: center;
  border-bottom: 5px solid transparent;
  padding: 0px 30px 80px 0px;
`;

export const Head = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 10px;
`;

export const ListaItem = styled.div`
  flex: 1;
  
  color: rgba(87, 87, 87, .35);
  font-family: Redonda, sans-serif;
 
  transition: all .2s ease;

  font-size: 2vh;
  font-weight: bold;
  text-transform: uppercase;
  padding: 15px;
  position: relative;
  min-width: 120px;

  /* Linha vertical */
  &:not(:last-child)::after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 2px;
    background-color: rgba(87, 87, 87, 0.8);
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
  }
    &:hover {
    color: #545454;  /* Muda a cor do texto */
    border-bottom: 5px solid #13A640;
  }
`;

export const Container = styled.div`
    padding: 5.625rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    font-size: 1rem;
    color: var(--black-color);
  
  .ReactMarkdown {
  text-align: justify;
    padding: 5vw;
    background: rgba(247, 238, 238, 0.5);
    border-radius: 8px;
    p {
      margin-bottom: 1.5rem;
      width: 90%;
      max-width: 44.625rem;
      :nth-last-child(2) {
        margin-bottom: 3rem;
      }
    }
  }
  
  .ativo, .listaItem:hover {
  color: #545454;  /* Muda a cor do texto */
  border-bottom: 5px solid #13A640;
}


  `;


