import React, { useEffect } from 'react';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { Footer } from './components/Footer';
import { Header } from './components/Header';
import { GlobalStyle } from './GlobalStyle';
import { AgriculturalInputs } from './pages/AgriculturalInputs';
import { CopasulCulture } from './pages/CopasulCulture';
import { Governance } from './pages/Governance';
import { GrainCommercial } from './pages/GrainCommercial';
import { Home } from './pages/Home';
import { Irrigation } from './pages/Irrigation';
import { News } from './pages/News';
import { PageNew } from './pages/News/components/PageNew';
import { OurHistory } from './pages/OurHistory';
import { OurUnits } from './pages/OurUnits';
import { PhotoGallery } from './pages/PhotoGallery';
import { Photos } from './pages/PhotoGallery/components/Photos';
import { ReportsDocuments } from './pages/ReportsDocuments';
import { SolarEnergy } from './pages/SolarEnergy';
import { SrSakae } from './pages/SrSakae';
import { Starch } from './pages/Starch';
import { Sustainability } from './pages/Sustainability';
import { TechnicalAssistence } from './pages/TechnicalAssistence';
import { TrrCopasul } from './pages/TrrCopasul';
import { Wiring } from './pages/Wiring';
import { VideoGallery } from './pages/VideoGallery';
import { RuralCopasul } from './pages/RuralCopasul';
import { Magazines } from './pages/Magazines';
import { Contact } from './pages/Contact';
import { WorkWithUs } from './pages/WorkWithUs';
import { PressRoom } from './pages/PressRoom';
import { Interviwes } from './pages/Interviwes';
import { PageInterview } from './pages/Interviwes/components/PageInterview';
import { EventCopasul } from './pages/EventCopasul';
import { BuildingSoils } from './pages/BuildingSoils';
import { useStartupState } from './Providers/StartupState';
import { NotFound } from './pages/NotFound';
import { PrivacyPolicy } from './pages/PrivacyPolicy';

export const App = () => {
  const { error } = useStartupState();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (window.location.href.toLowerCase().indexOf('localhost') === -1) {
      window.dataLayer = window.dataLayer || [];
      function gtag() { window.dataLayer.push(arguments); }
      let page = window.location.pathname;
      if (page.startsWith('/'))
        page = page.substring(1);

      if(page === '')
        page = 'home';

      gtag('event', 'page_view', {
        page_location: window.location.href,
        page_path: `/${page}`,
        page_title: page
      });
      console.log(page);
    }
  }, [location]);

  if (error) return navigate('*');
  return (
    <div className="App">
      <Header />
      <GlobalStyle />
      <Routes>
        <Route path="/" element={<Home />} end />
        <Route path="nossaHistoria" element={<OurHistory />} />
        <Route path="comercialDeGraos/*" element={<GrainCommercial />} />
        <Route path="insumosAgricola" element={<AgriculturalInputs />} />
        <Route path="governanca" element={<Governance />} />
        <Route path="culturaCopasul" element={<CopasulCulture />} />
        <Route path="srSakae" element={<SrSakae />} />
        <Route path="fiacao" element={<Wiring />} />
        <Route path="fecularia" element={<Starch />} />
        <Route path="irrigacao" element={<Irrigation />} />
        <Route path="energiaSolar" element={<SolarEnergy />} />
        <Route path="trrCopasul" element={<TrrCopasul />} />
        <Route path="assistenciaTecnica" element={<TechnicalAssistence />} />
        <Route path="nossasUnidades" element={<OurUnits />} />
        <Route path="construindoSolos" element={<BuildingSoils />} />
        <Route path="sustentabilidade" element={<Sustainability />} />
        <Route path="relatoriosDocumentos" element={<ReportsDocuments />} />
        <Route path="noticias" element={<News />} />
        <Route path="paginaNoticia/:idNew" element={<PageNew />} />
        <Route path="galeriaDeFotos" element={<PhotoGallery />} />
        <Route path="fotos/:idPhoto/*" element={<Photos />} />
        <Route path="galeriaDeVideos" element={<VideoGallery />} />
        <Route path="copasulRural" element={<RuralCopasul />} />
        <Route path="revistasCopasul" element={<Magazines />} />
        <Route path="salaDeImprensa" element={<PressRoom />} />
        <Route path="entrevistas" element={<Interviwes />} />
        <Route path="paginaEntrevista/:idEntrevista" element={<PageInterview />} />
        <Route path="eventosCopasul" element={<EventCopasul />} />
        <Route path="trabalheConosco" element={<WorkWithUs />} />
        <Route path="contato" element={<Contact />} />
        <Route path="politicaPrivacidade" element={<PrivacyPolicy />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </div>
  );
};
