import styled, { css } from 'styled-components';

export const CustomSelectContainer = styled.div`
  position: relative;
  width: 100%;
  width:230px;
`;

export const SelectBox = styled.div`
  ${({ src }) => css`
  display: flex;
  align-items: center;
  padding: 1px;
  border: 1px solid #a8a6a6;
  border-radius: 0.625rem;
  cursor: pointer;
  background: white;
  position: relative;
  svg {
      position: absolute;
      margin-left: 0.5rem;
      color: ${src ? 'var(--white-color);' : 'var(--octave-color);'};
      &:hover {
      transform: scale(1.1); /* Aumenta ligeiramente */
      box-shadow: 0 6px 10px rgba(221, 213, 213, 0.3); /* Sombra mais intensa */
    }
    }
  `}
`;

export const InputField = styled.input`
  width: 100%;
  border: none;
  outline: none;
  font-size: 0.875rem; /* Ajustando para ficar próximo da versão anterior */
  padding-left: 2.5rem; /* Espaço para o ícone */
  color: ${({ src }) => (src ? 'var(--white-color)' : 'var(--octave-color)')}; /* Cor dinâmica */
  background-color: transparent; /* Para garantir que o fundo seja transparente */
  
`;

export const Arrow = styled.span`
  font-size: 25px;
  margin-right: 10px;
  /* position: absolute; */
  display: flex;
  justify-content: end;
  color: ${({ src }) => (src ? 'var(--white-color)' : 'var(--octave-color)')}; /* Cor dinâmica */
`;

export const OptionsList = styled.ul`
  ${({ src }) => css`
    position: absolute;
    width: 100%;
    background: white;
    border-radius: 0.625rem;
    border: 1px solid;
    border-color: ${src ? 'var(--white-color)' : 'var(--octave-color)'};
    color: ${src ? 'var(--white-color)' : 'var(--octave-color)'};
    list-style: none;
    padding: 5px 0;
    margin-top: 5px;
    max-height: 300px;
    overflow-y: auto;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1;
  `}
`;

export const OptionItem = styled.li`
  padding: 10px;
  cursor: pointer;
  transition: background 0.2s;

  &:hover {
    background: #f0f0f0;
  }
`;

export const NoOption = styled.li`
  padding: 10px;
  color: gray;
  text-align: center;
`;
