/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHeaderStates } from '../../Providers/HeaderStates';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import { Loading } from '../../components/Loading';
import { useStartupState } from '../../Providers/StartupState';
import { baseUrlImg, handleGetData } from '../../Services';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import * as S from './style';


export const PrivacyPolicy = () => {
  const [data, setData] = useState(null);
  const { setTitle, setBgHeader } = useHeaderStates();
  const [selectedOption, setSelectedOption] = useState(0); // Nenhum aberto inicialmente
  const { token, setError, loading, setLoading } = useStartupState();

  useEffect(() => {
    setError(null);
    setLoading(true);

    if (token) {
      handleGetData(token, "/api/content/copasul/politicaprivacidade/")
        .then((response) => {
          setData(response?.data?.items[0]?.data);

          if (response?.status !== 200) {
            throw new Error(response.message);
          }
        })
        .catch((e) => {
          setData(null);
          console.log(e.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [token]);

  useEffect(() => {
    if (data) {
      setTitle(data?.seo?.iv?.TituloSEO);
      setBgHeader(data?.imagemHeader?.iv[0]);
    }
  }, [data]);

  const handleActionButton = (index) => {
    setSelectedOption(index); // Mantém o último clicado ativo
  };

  return (
    <>
      <div style={{ position: "relative" }}>
        <Breadcrumbs
          content={data?.breadScrumb?.iv}
          style={{ position: "absolute" }}
        />
      </div>

      <S.Container bgPrivacyPolicy={data?.imagemFundo?.iv[0]}>
        <S.AbasContainer>
          <S.Head>
            {data?.informacoesPrivacidade?.iv.map((item, index) => (
              <S.ListaItem
                key={index}
                onClick={() => handleActionButton(index)}
                className={selectedOption === index ? "ativo" : ""}
              >
                {item.tituloMarkdown}
              </S.ListaItem>
            ))}
          </S.Head>
        </S.AbasContainer>

        {/* Exibir apenas se houver algo selecionado */}
        {selectedOption !== null && (
          <ReactMarkdown className="ReactMarkdown">
            {data?.informacoesPrivacidade?.iv[selectedOption]?.descricaoMarkdown}
          </ReactMarkdown>
        )}
      </S.Container>
    </>
  );
};
