import React, { useState, useRef, useEffect } from "react";
import SearchIcon from '@mui/icons-material/Search';
import {
  CustomSelectContainer,
  SelectBox,
  InputField,
  Arrow,
  OptionsList,
  OptionItem,
  NoOption,
} from "./style";

const CustomDropdown = ({ optionsMenu, onSelect }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setIsOpen(true);
  };

  const handleSelectOption = (item) => {
    setSearchTerm(item.title);
    setIsOpen(false);
    if (onSelect) {
      onSelect(item.route);
    }
  };

  const filteredOptions = optionsMenu.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Fecha o dropdown ao clicar fora
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <CustomSelectContainer ref={dropdownRef}>
      <SelectBox onClick={() => setIsOpen(!isOpen)}>
      <SearchIcon />
        <InputField
          type="text"
          placeholder="Faça sua busca aqui..."
          value={searchTerm}
          onChange={handleSearchChange}
          onFocus={() => setIsOpen(true)} // Mostra opções ao focar
        />
        <Arrow>&#9662;</Arrow> {/* Ícone de seta */}
      </SelectBox>

      {isOpen && (
        <OptionsList>
          {filteredOptions.length > 0 ? (
            filteredOptions.map((item, index) => (
              <OptionItem key={index} onClick={() => handleSelectOption(item)}>
                {item.title}
              </OptionItem>
            ))
          ) : (
            <NoOption>Nenhuma opção encontrada</NoOption>
          )}
        </OptionsList>
      )}
    </CustomSelectContainer>
  );
};

export default CustomDropdown;