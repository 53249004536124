import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const FooterBox = styled.footer`
  font-size: 0.813rem !important;
  color: var(--first-color);
  background: linear-gradient(45deg, rgba(96, 125, 139, 0.3), rgba(165, 214, 167, 0.3), rgba(165, 214, 167, 0.3), rgba(96, 125, 139, 0.3));

  /* background: linear-gradient(30deg, var(--tenth-color) 0%, #aeb08f 50%, #dde0cb 100%); */
  border: 1px solid transparent;
`;

export const TopPage = styled.a`
  background-color: var(--fourth-color);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: -25px auto;
  z-index: 100;
  position: relative;
  svg {
    height: 50px;
    width: 50px;
    color: var(--white-color);
  }
`;

export const FooterContentBox = styled.div`
  display: flex;
  box-sizing: border-box;
  padding: 25px 60px;
  margin-left: 90px;
  gap: 1rem;
  flex-wrap: wrap;
  position: relative;
`;

export const ColumnLinkBox = styled.div`
  gap: 1.5rem;
  box-sizing: border-box;
  /* overflow: hidden; */
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  @media only screen and (min-width: 533px) {
    height: 976px;
  }
  @media only screen and (min-width: 800px) {
    height: 543px;
  }
  @media only screen and (min-width: 1000px) {
    height: 349px;
  }
  @media only screen and (min-width: 1366px) {
    height: 180px;
  }
`;

export const ColumnBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  gap: 0.5rem;
  
  @media only screen and (min-width: 533px) {
    width: 50%;
    height: 100%;
  }
  @media only screen and (min-width: 800px) {
    width: 13.125rem;
  }

  img {
    width: 10.216rem;
  }
  p {
    svg {
      width: 1rem;
      margin-bottom: -7px;
      margin-right: 0.3rem;
    }
  }
`;

export const Description = styled.p`
  margin-top: 3rem;
`;

export const TitlesColumn = styled(Link)`
  color: var(--second-color);
  height: fit-content;
  width: 100%;
  display: grid;
  gap: 1rem;
  font-family: 'Inter';
  font-style: normal;
  font-size: 13px;
  line-height: 10px;

  @media only screen and (min-width: 533px) {
    width: 50%;
    /* justify-content: center; */
  }
  @media only screen and (min-width: 800px) {
    width: 30%;
  }
  @media only screen and (min-width: 1000px) {
    width: 15%;
  }
  a{
    color: var(--second-color);
    &:hover {
    transform: scale(1.05); /* Cresce um pouco mais */
    transition: transform 0.2s cubic-bezier(0.25, 1, 0.5, 1), box-shadow 0.3s ease-in-out;
}
  }
`;

export const BoldTitlesColumn = styled(Link)`
color: #0f4e2b !important;
  font-weight: bold;
  width: 100%;
  height: 100%;
  display: grid;

  &:hover {
  transform: scale(1.05); /* Cresce um pouco mais */
  transition: transform 0.2s cubic-bezier(0.25, 1, 0.5, 1), box-shadow 0.3s ease-in-out;
}
`;

export const SocialNetworksBox = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  font-weight: 700;
  color: var(--second-color);
  line-height: 0;
  p{
    margin: 15px 0px 5px 0px;
  }
  @media only screen and (min-width: 1000px) {
    /* margin-top: 100px; */
  }
`;
export const SocialNetworks = styled.div`
  display: flex;
  border: 1px solid var(--second-color);
  padding: 0.425rem;
  border-radius: 0.625rem;
  justify-content: space-between;
  gap: 1.063rem;
  width: fit-content;
  margin: 1rem 0 0 0;
  button {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0;
    width: 20px;
  }
`;

export const PartnersBox = styled.div`
  display: flex;
  font-size: 0.875rem;
  font-weight: 700;
  color: var(--second-color);
`;

export const Partners = styled.div`
  display: flex;
  border-radius: 0.625rem;
  margin-left: auto; 
  flex-wrap: wrap;
  margin-top: 10px;
  .footer-logo{
    width: 130px;
    margin: 0px 20px 0px 20px;
  }
`;

export const LogoFooterBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center; 
  flex-wrap: wrap;
  width: 100%; 
  margin-top: 40px;

  &::after {
    content: "";
    display: block;
    width: 70%; 
    border-bottom: 1px solid var(--second-color);
  }

  @media only screen and (min-width: 533px) {
    justify-content: flex-end;
  }

  @media only screen and (min-width: 800px) {
    justify-content: center; /* Centraliza a linha novamente */
  }
`;


export const copyright = styled.p`
display: flex;
justify-content: center;
flex-wrap: wrap;
  text-align: center;
  padding: 12px;
  font-size: 10.5px;
`;